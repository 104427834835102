


























import Vue from 'vue';
import N8nIcon from '../N8nIcon';
import N8nSpinner from '../N8nSpinner';

export default Vue.extend({
	name: 'n8n-button',
	props: {
		label: {
			type: String,
		},
		type: {
			type: String,
			default: 'primary',
			validator: (value: string): boolean =>
				['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger'].includes(value),
		},
		size: {
			type: String,
			default: 'medium',
			validator: (value: string): boolean =>
				['mini', 'small', 'medium', 'large', 'xlarge'].includes(value),
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		outline: {
			type: Boolean,
			default: false,
		},
		text: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: [String, Array],
		},
		block: {
			type: Boolean,
			default: false,
		},
		float: {
			type: String,
			validator: (value: string): boolean =>
				['left', 'right'].includes(value),
		},
	},
	components: {
		N8nSpinner,
		N8nIcon,
	},
	computed: {
		ariaBusy(): string {
			return this.loading ? 'true' : 'false';
		},
		ariaDisabled(): string {
			return this.disabled ? 'true' : 'false';
		},
		classes(): string {
			return `button ${this.$style['button']} ${this.$style[this.type]}` +
				`${this.size ? ` ${this.$style[this.size]}` : ''}` +
				`${this.outline ? ` ${this.$style['outline']}` : ''}` +
				`${this.loading ? ` ${this.$style['loading']}` : ''}` +
				`${this.float ? ` ${this.$style[`float-${this.float}`]}` : ''}` +
				`${this.text ? ` ${this.$style['text']}` : ''}` +
				`${this.disabled ? ` ${this.$style['disabled']}` : ''}` +
				`${this.block ? ` ${this.$style['block']}` : ''}` +
				`${this.icon || this.loading ? ` ${this.$style['icon']}` : ''}`;
		},
	},
});
