



































































import { INodeUi } from '@/Interface';
import { IConnectedNode, Workflow } from 'n8n-workflow';
import RunData from './RunData.vue';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import mixins from 'vue-typed-mixins';
import NodeExecuteButton from './NodeExecuteButton.vue';
import WireMeUp from './WireMeUp.vue';
import { CRON_NODE_TYPE, INTERVAL_NODE_TYPE, LOCAL_STORAGE_MAPPING_FLAG, START_NODE_TYPE } from '@/constants';

export default mixins(
	workflowHelpers,
).extend({
	name: 'InputPanel',
	components: { RunData, NodeExecuteButton, WireMeUp },
	props: {
		currentNodeName: {
			type: String,
		},
		runIndex: {
			type: Number,
		},
		linkedRuns: {
			type: Boolean,
		},
		workflow: {
		},
		canLinkRuns: {
			type: Boolean,
		},
		sessionId: {
			type: String,
		},
		readOnly: {
			type: Boolean,
		},
	},
	data() {
		return {
			showDraggableHintWithDelay: false,
			draggableHintShown: false,
		};
	},
	computed: {
		focusedMappableInput(): string {
			return this.$store.getters['ui/focusedMappableInput'];
		},
		isUserOnboarded(): boolean {
			return window.localStorage.getItem(LOCAL_STORAGE_MAPPING_FLAG) === 'true';
		},
		showDraggableHint(): boolean {
			const toIgnore = [START_NODE_TYPE, CRON_NODE_TYPE, INTERVAL_NODE_TYPE];
			if (!this.currentNode || toIgnore.includes(this.currentNode.type)) {
				return false;
			}

			return !!this.focusedMappableInput && !this.isUserOnboarded;
		},
		isExecutingPrevious(): boolean {
			if (!this.workflowRunning) {
				return false;
			}
			const triggeredNode = this.$store.getters.executedNode;
			const executingNode = this.$store.getters.executingNode;
			if (this.activeNode && triggeredNode === this.activeNode.name && this.activeNode.name !== executingNode) {
				return true;
			}

			if (executingNode || triggeredNode) {
				return !!this.parentNodes.find((node) => node.name === executingNode || node.name === triggeredNode);
			}
			return false;
		},
		workflowRunning (): boolean {
			return this.$store.getters.isActionActive('workflowRunning');
		},
		currentWorkflow(): Workflow {
			return this.workflow as Workflow;
		},
		activeNode (): INodeUi | null {
			return this.$store.getters.activeNode;
		},
		currentNode (): INodeUi | null {
			return this.$store.getters.getNodeByName(this.currentNodeName);
		},
		connectedCurrentNodeOutputs(): number[] | undefined {
			const search = this.parentNodes.find(({name}) => name === this.currentNodeName);
			if (search) {
				return search.indicies;
			}
			return undefined;
		},
		parentNodes (): IConnectedNode[] {
			if (!this.activeNode) {
				return [];
			}
			const nodes: IConnectedNode[] = (this.workflow as Workflow).getParentNodesByDepth(this.activeNode.name);

			return nodes.filter(({name}, i) => (this.activeNode && (name !== this.activeNode.name)) && nodes.findIndex((node) => node.name === name) === i);
		},
		currentNodeDepth (): number {
			const node = this.parentNodes.find((node) => this.currentNode && node.name === this.currentNode.name);
			return node ? node.depth: -1;
		},
	},
	methods: {
		onNodeExecute() {
			this.$emit('execute');
			if (this.activeNode) {
				this.$telemetry.track('User clicked ndv button', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: 'input',
					type: 'executePrevious',
				});
			}
		},
		onRunIndexChange(run: number) {
			this.$emit('runChange', run);
		},
		onLinkRun() {
			this.$emit('linkRun');
		},
		onUnlinkRun() {
			this.$emit('unlinkRun');
		},
		onSelect(value: string) {
			const index = this.parentNodes.findIndex((node) => node.name === value) + 1;
			this.$emit('select', value, index);
		},
		onConnectionHelpClick() {
			if (this.activeNode) {
				this.$telemetry.track('User clicked ndv link', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: 'input',
					type: 'not-connected-help',
				});
			}
		},
		truncate(nodeName: string) {
			const truncated = nodeName.substring(0, 30);
			if (truncated.length < nodeName.length) {
				return `${truncated}...`;
			}
			return truncated;
		},
	},
	watch: {
		showDraggableHint(curr: boolean, prev: boolean) {
			if (curr && !prev) {
				setTimeout(() => {
					if (this.draggableHintShown) {
						return;
					}
					this.showDraggableHintWithDelay = this.showDraggableHint;
					if (this.showDraggableHintWithDelay) {
						this.draggableHintShown = true;

						this.$telemetry.track('User viewed data mapping tooltip', { type: 'unexecuted input pane' });
					}
				}, 1000);
			}
			else if (!curr) {
				this.showDraggableHintWithDelay = false;
			}
		},
	},
});
